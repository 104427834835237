import dmshttp from "@/utils/dmsrequest";

//库存调整列表
export function getStockTransProductDetailList(data) {
    return dmshttp({
        url: "getStockTransProductDetailList",
        data
    });
}
export function exportStock(data) {
    return dmshttp({
        url: "exportStockTransProductDetailList&type=1",
        data,
        responseType: 'blob'
    });
}

export function getImg(data){
    return dmshttp({
        url:'toOmsView',
        data
    })
}
export function getStatsInfo(data) {
    return dmshttp({
      url: "getDealerStockTransStatsInfo",
      data
    });
  }