import {
  getStockTransProductDetailList,
  exportStock,
  getImg,
  getStatsInfo
} from "./api.js";
import {
  cancleLog
} from "@/views/order/retailOrder/details/api";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      modelShowsale:false,
      pageLoadFlag: false,
      expLoading: false,
      navList: [{
        title: "库存调整列表",
        path: "/stockCenter/stockAdjustment",
        active: true
      }],
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/myStock",
          name: "/stockCenter/myStock",
          title: "库存调整明细"
        }
      ],
      isLoginModalShow: false,
      cancleId: '',
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: '',
        sortOrder: '',
      },
      scroll: {
        x: 3000
        // y: 400
      },
      columns: [{
          label: "申请单号",
          prop: "documentNum",
          ellipsis: true,
          sortable: 'custom',
          sortName: 'sta.document_num',
          width: "200px",
          key: "documentNum"
        },
        {
          label: "事务类型",
          prop: "transactionTypeName",
          ellipsis: true,
          sortable: 'custom',
          sortName: 'sta.transaction_type',
          key: "transactionTypeName"
        },
        {
          label: "状态",
          prop: "statusName",
          ellipsis: true,
          sortable: 'custom',
          sortName: 'sta.status',
          key: "statusName"
        },
        {
          label: "销售组织",
          prop: "orgName",
          ellipsis: true,
          sortable: 'custom',
          sortName: 'sta.org_id',
          key: "orgName"
        },
        {
          label: "移出仓库",
          prop: "gicOutWarehouseName",
          ellipsis: true,
          key: "gicOutWarehouseName"
        },
        {
          label: "移入仓库",
          prop: "gicInWarehouseName",
          ellipsis: true,
          key: "gicInWarehouseName"
        },
        {
          label: "合计体积(m³)",
          prop: "volumeTotal",
          ellipsis: true,
          align: 'right',
          key: "volumeTotal"
        },
        {
          label: "物流状态",
          prop: "lastOmsOrderStatusName",
          ellipsis: true,
          sortable: 'custom',
          sortName: 'oms.order_status',
          key: "lastOmsOrderStatusName"
        },
        {
          label: "预计送达时间",
          prop: "reserveTime",
          ellipsis: true,
          key: "reserveTime"
        },
        {
          label: "产品型号",
          prop: "model",
          width: "200px",
          ellipsis: true,
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          key: "materialGroupName"
        },
        // {
        //   label: "库存状态",
        //   prop: "invStatusName",
        //   key: "invStatusName"
        // },
        {
          label: "质量等级",
          prop: "invStatusName",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          key: "invStatusTypeName"
        },
        {
          label: "调整后质量等级",
          prop: "alertInvStatusName",
          key: "alertInvStatusName"
        },
        {
          label: "调整后补差类型",
          prop: "alertInvStatusTypeName",
          key: "alertInvStatusTypeName"
        },
        {
          label: "调整数量",
          prop: "bdemandQty",
          align: 'right',
          key: "bdemandQty"
        },
        {
          label: "备注",
          prop: "message",
          key: "message"
        },
        {
          label: "审批意见",
          prop: "checkMemo",
          key: "checkMemo"
        },
        {
          label: "申请时间",
          prop: "documentDate",
          ellipsis: true,
          width: "200px",
          key: "documentDate"
        },
        {
          label: "申请账号",
          prop: "dealerCode",
          ellipsis: true,
          width: "200px",
          key: "dealerCode"
        },
        {
          label: "申请平台",
          prop: "dataSourceValue",
          key: "dataSource"
        },
        {
          label: "操作",
          width: 180,
          fixed: "right",
          slot: "btnGroup",
        }
      ],
      columnsAll:[],
      data: [],
      terms: {},
      onluyOneMessage: false,
      imgURL: [],
      showLoading:false,
      salesTabledata: [],

    };
  },
  created() {
    // this.getData();
    this.getSaleInfo()
    this.columnsAll = this.columns;
  },
  methods: {
    getSaleInfo() {
      let data = {
        account: this.$store.state.user.userInfo.account.account
      }
      getStatsInfo(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        let arr1 = [];
        let arr2 = [];
        data.data.forEach(item => {
          arr1.push({
            ckun: item.monthlyStatsSalesCount,
            dbnum: item.monthlyStatsTransCount,
            total: item.monthlyStatsTotal,
            dbbl: item.monthlyStatsTransRate,
            dbblxz: item.availableMaxTransRate,
            id: "当前月累 （考核指标）",
            name: item.orgName
          });
          arr2.push({
            name: item.orgName,
            ckun: item.statsTransCount,
            dbnum: item.statsTransCount,
            total: item.statsTotal,
            dbbl: item.statsTransRate,
            dbblxz: item.availableMaxTransRate,
            id: "近 30 天累计（计算规则）"
          });
        });
        this.salesTabledata = arr1.concat(arr2);

        this.salesTabledata.forEach((item, index) => {
          if (index > 2) {
            item.id = "近 30 天累计（计算规则）";
          } else {
            item.id = `当前月累 \xa0\xa0（考核指标）`;
          }
        });
        this.$forceUpdate();
      });
    },
    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        var realData = JSON.parse(res.data);
        if (realData.data) {
          this.imgURL = realData.data;
          this.onluyOneMessage = true;
        } else {
          this.$message.warning('暂无回单影像');
        }

      })
    },
    canclewuliuShow(row) {
      this.cancleId = row.stvId
      this.isLoginModalShow = true;
      // this.canclewuliu()
    },
    canclewuliu() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        stvId: this.cancleId,
        invStatusType: ''
      }
      this.pageLoadFlag = true;
      cancleLog(data).then(res => {
        var realData = JSON.parse(res.data);
        this.pageLoadFlag = false;
        if (realData.code == '1') {
          this.$message.warning(realData.msg);
        } else {
          this.$message.success(realData.msg)
        }
        this.getData();
      })
    },
    searchFun(arg) {
      this.terms = arg[1];
      this.pager.pageNo = 1
      this.getData();
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.columns.filter(it => it.prop == sorter.prop)
      if (sortName.length < 1) {
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
          sorter.order == "ascending" ?
          "asc" :
          sorter.order == "descending" ?
          "desc" :
          "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData()
    },
    exportFile() {
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        }
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.msg == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '库存调整记录.xls')
        }).finally(() => {
          this.expLoading = false;
        })
      }).catch(err => {
        this.expLoading = false;
      });
    },
    getData() {
      this.pageLoadFlag = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          // documentNum: "",
          // documentDateFrom: "",
          // documentDateTo: "",
          // warehouseId: "",
          // transactionType: "",
          // status: "",
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        }
      };
      this.showLoading = true
      getStockTransProductDetailList(data).then(res => {
        this.pageLoadFlag = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        data.data = data.data || []
        data.data.forEach(it => {
          it.bdemandQty = parseInt(it.bdemandQty)
          it.volumeTotal = new Number(it.volumeTotal).toFixed(3)
        })
        this.data = data.data;
        this.pager.count = data.page.totalCount;
        this.showLoading = false
      }).finally(()=>{
        this.pageLoadFlag = false;
      })
    },
    //新增库存调整
    addNewAdjustment() {
      this.$router.push("/stockCenter/adjustmentApply");
    },
    initTableColumns(tableColumns) {
      this.columns = tableColumns.filter(j=>j.isShow === '1');
      // this.columns.push(
      //   {
      //     label: "操作",
      //     width: 180,
      //     fixed: "right",
      //     slot: "btnGroup",
      //   }
      // );
    },
  }
};